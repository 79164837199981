import { useContext } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { routes } from "./app.routing";
import { GlobalStateContext } from "./shared/GlobalStateContext";

const IdleLogout = () => {
	const navigate = useNavigate();
	const { setIsAuthenticated } = useContext(GlobalStateContext);

	const idleTimeout = 900000; // 15 minutes

	const handleOnIdle = () => {
		toast.success("Session has expired !! Please Re-Login");
		setIsAuthenticated(false);
		navigate("/");
	};

	const _idleTimer = useIdleTimer({
		timeout: idleTimeout,
		onIdle: handleOnIdle,
		debounce: 500,
	});

	return <>{routes}</>;
};

export default IdleLogout;
