import moment from "moment";
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";

const CustomDateFilter = forwardRef((props, ref) => {
	const [filterValue, setFilterValue] = useState("");
	const inputRef = useRef();

	// biome-ignore lint/correctness/useExhaustiveDependencies: <filterValue is needed for this filter to work>
	useEffect(() => {
		if (props.filterChangedCallback) {
			props.filterChangedCallback();
		}
	}, [filterValue, props.filterChangedCallback]);

	useImperativeHandle(ref, () => ({
		isFilterActive() {
			return filterValue !== "";
		},
		doesFilterPass(params) {
			const cellValue = moment(params.data[props.column.colId]).format(
				"MM-DD-yyyy",
			);
			if (!cellValue) return false;
			const cellDate = moment(cellValue, "MM-DD-yyyy").toDate();
			const filterDate = moment(filterValue, "MM-DD-yyyy").toDate();
			return cellDate >= filterDate;
		},
		getModel() {
			return { value: filterValue };
		},
		setModel(model) {
			setFilterValue(model ? model.value : "");
		},
	}));

	const onDateChange = (event) => {
		setFilterValue(event.target.value);
	};

	return (
		<div>
			<input
				type="text"
				ref={inputRef}
				placeholder="MM-DD-YYYY"
				value={filterValue}
				onChange={onDateChange}
			/>
		</div>
	);
});

export default CustomDateFilter;
