import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useContext, useState } from "react";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { useNavigate } from "react-router-dom";
import { GlobalStateContext } from "../../shared/GlobalStateContext";
import { validateUser } from "../../shared/services/login-api.services";
import { handleKeyDown } from "../../shared/utils";

import "./user.css";

const Login = () => {
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState("");
	const [password, setPassword] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [isValid, setIsValid] = useState(false);
	const [error, setError] = useState("");
	const [openLoading, setOpenLoading] = useState(false);
	const [type, setType] = useState("password");
	const [icon, setIcon] = useState(eyeOff);
	const { setIsAuthenticated, setUserRole, setUserName } =
		useContext(GlobalStateContext);
	const navigate = useNavigate();

	const handleLogin = async () => {
		if (!validateFields()) {
			return false;
		}
		setOpenLoading(true);
		const body = JSON.stringify({
			username: email,
			password: password,
		});
		const data = await validateUser(body);
		if (data.data !== null) {
			setOpenLoading(false);
			if (data.data.authenticated) {
				setUserRole(data.data.role);
				setUserName(data.data.name);
				setIsAuthenticated(true);
				navigate("/wb-home");
			} else {
				setIsAuthenticated(false);
				setIsValid(false);
				setError("Invalid User ID or Password");
			}
		}
	};

	const handleKeyPress = (_event) => {
		setEmailError("");
		setPasswordError("");
		setError("");
		setIsValid(false);
	};

	const handlePasswordToggle = () => {
		if (type === "password") {
			setIcon(eye);
			setType("text");
		} else {
			setIcon(eyeOff);
			setType("password");
		}
	};

	const validateFields = () => {
		setEmailError("");
		setPasswordError("");

		if ("" === email && "" === password) {
			setIsValid(false);
			setError("Please enter User ID and Password");
			return false;
		}

		if ("" === email) {
			setEmailError("Please enter your User ID");
			return false;
		}

		if ("" === password) {
			setPasswordError("Please enter a password");
			return false;
		}

		return true;
	};

	return (
		<>
			<div className="container cotainer-display" style={{ flex: "1" }}>
				<div className="row justify-content-center">
					<div class="col-xl-6 col-lg-12 col-md-12">
						<div class="card o-hidden border-0 shadow-lg my-5">
							<div class="card-body p-0">
								<div class="row">
									<div class="col-lg-12">
										<div class="p-5">
											<div class="text-center">
												<img class="logo" src="img/FRPLogo.svg" alt="logo" />
											</div>
											<br />
											<hr />
											<div class="form-group">
												<input
													type="text"
													className={
														emailError === "" && error === ""
															? "form-control form-control-user "
															: "form-control form-control-user errorTextbox"
													}
													id="loginID"
													aria-describedby="emailHelp"
													placeholder="User ID"
													value={email}
													onChange={(e) => setEmail(e.target.value)}
													onKeyUpCapture={handleKeyPress}
												/>
												<label htmlFor="loginID" className="emailErrorLabel">
													{emailError}
												</label>
											</div>
											<div>
												<div className="input-icons">
													<i
														id="eyeIcon"
														className="flex justify-around items-center"
														onClick={handlePasswordToggle}
														onKeyDown={(e) =>
															handleKeyDown(e, handlePasswordToggle)
														}
														tabIndex="0"
													>
														<Icon
															className="absolute mr-10 icon"
															icon={icon}
															size={20}
														/>
													</i>
													<input
														type={type}
														className={
															passwordError === "" && error === ""
																? "form-control form-control-user "
																: "form-control form-control-user errorTextbox"
														}
														id="exampleInputPassword"
														placeholder="Password"
														value={password}
														onChange={(e) => setPassword(e.target.value)}
														onKeyUpCapture={handleKeyPress}
														maxLength={8}
													/>
												</div>
												<label
													className="passwordErrorLabel"
													htmlFor="exampleInputPassword"
												>
													{passwordError}{" "}
												</label>
												<label
													className="passwordErrorLabel"
													id="isValidUser"
													htmlFor="loginID"
												>
													{isValid ? null : <>{error}</>}
												</label>
											</div>
											<button
												type="submit"
												className="btn button-primary1 btn-user btn-block "
												onClick={handleLogin}
												onKeyDown={(e) => handleKeyDown(e, handleLogin)}
												tabIndex="0"
											>
												Login
											</button>
											<br />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={openLoading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
};

export default Login;
