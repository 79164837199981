import { Route, Routes } from "react-router-dom";

import Login from "./components/user/login";
import PermanentIssueRegister from "./components/wedge-builder/permanent-issue-register";
import WBHome from "./components/wedge-builder/wb-home";
import WBOutputReview from "./components/wedge-builder/wb-output-review";
import Protected from "./shared/protected-routing";

export const routes = [
	<Routes key="routes-1">
		<Route exact path="/" Component={Login} />

		<Route
			exact
			path="/wb-home"
			element={
				<Protected component={"wb-home"}>
					<WBHome />
				</Protected>
			}
		/>

		<Route
			exact
			path="/wb-output-review"
			element={
				<Protected component={"wb-output-review"}>
					<WBOutputReview />
				</Protected>
			}
		/>

		<Route
			exact
			path="/permanent-issue-register"
			element={
				<Protected component={"permanent-issue-register"}>
					<PermanentIssueRegister />
				</Protected>
			}
		/>
	</Routes>,
];
