const ImageCellRenderer = (props) => {
	const base64Image = props.value;
	const imageUrl = `data:application/pdf;base64,${base64Image}`;

	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				paddingTop: "5px",
			}}
		>
			{props.value === "" || props.value === null ? null : (
				<iframe
					title="image-cell-renderer"
					src={imageUrl}
					type="application/jpeg"
					width="100%"
					height="670px"
				/>
			)}
		</div>
	);
};

export default ImageCellRenderer;
