import { getAPI, postAPI } from "../services/api-services";

const baseURL = process.env.REACT_APP_API;

export const getOutputReview = async (caseID) => {
	const url = `${baseURL}/api/getOutputAnalysisTable?case_id=${caseID}`;
	const { data, loading, error } = await getAPI(url);
	return { data, error, loading };
};

export const postOutputReview = async (caseID, body, action) => {
	const url = `${baseURL}/api/saveAndConfirmResults/?case_id=${caseID}&action=${action}`;
	const { data, loading, error } = await postAPI(url, body);
	return { data, error, loading };
};
