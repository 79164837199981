import { Autocomplete } from "@mui/material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import "../../../index.css";

const DropdownCellRenderer = (props) => {
	const [selectedValue, setSelectedValue] = useState(props.value);

	const handleChange = (event) => {
		setSelectedValue(event.label);
		props.node.setDataValue(props.colDef.field, event.label);
	};

	return (
		<Stack spacing={1} sx={{ width: 120, paddingTop: "5px" }}>
			<Autocomplete
				disableClearable
				id="module-dropdown"
				value={
					selectedValue == null || selectedValue === ""
						? "Select"
						: selectedValue
				}
				options={props.options}
				sx={{ width: 120, minHeight: 1 }}
				ListboxProps={{ style: { maxHeight: 250 } }}
				onChange={(_e, v) => handleChange(v)}
				renderOption={(props, option) => {
					return (
						<li {...props} key={option.Id}>
							<span
								className="autoComplete-list-review"
								style={{ fontSize: "0.875rem" }}
							>
								{option.label}
							</span>
						</li>
					);
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="standard"
						size="small"
						InputProps={{
							...params.InputProps,
							inputProps: {
								...params.inputProps,
								style: { fontSize: "0.875rem" },
							},
						}}
					/>
				)}
			/>
		</Stack>
	);
};

export default DropdownCellRenderer;
