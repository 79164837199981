import { createContext, useEffect, useState } from "react";

const GlobalStateContext = createContext();

const GlobalStateProvider = ({ children }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(() => {
		const savedIsAuthenticated = localStorage.getItem("isAuthenticated");
		return savedIsAuthenticated ? savedIsAuthenticated : false;
	});

	const [userRole, setUserRole] = useState(() => {
		const savedUserRole = localStorage.getItem("userRole");
		return savedUserRole ? savedUserRole : "";
	});

	const [currentRoute, setCurrentRoute] = useState(() => {
		const savedCurrentRoute = localStorage.getItem("currentRoute");
		return savedCurrentRoute ? savedCurrentRoute : "/";
	});

	const [userName, setUserName] = useState(() => {
		const savedUserName = localStorage.getItem("userName");
		return savedUserName ? savedUserName : "";
	});
	const [selectedModule, setSelectedModule] = useState("Wedge Builder");

	useEffect(() => {
		localStorage.setItem("isAuthenticated", isAuthenticated);
		localStorage.setItem("userRole", userRole);
		localStorage.setItem("currentRoute", currentRoute);
		localStorage.setItem("userName", userName);
		localStorage.setItem("selectedModule", selectedModule);
	}, [isAuthenticated, userRole, currentRoute, userName, selectedModule]);

	return (
		<GlobalStateContext.Provider
			value={{
				isAuthenticated,
				setIsAuthenticated,
				userRole,
				setUserRole,
				currentRoute,
				setCurrentRoute,
				userName,
				setUserName,
				selectedModule,
				setSelectedModule,
			}}
		>
			{children}
		</GlobalStateContext.Provider>
	);
};

export { GlobalStateContext, GlobalStateProvider };
