import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import * as moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { GlobalStateContext } from "../../shared/GlobalStateContext";
import CustomDateFilter from "../../shared/ag-grid/filters/customeDateFilter";
import FRPAgGrid from "../../shared/ag-grid/frp-ag-grid";
import Header from "../../shared/layouts/header";
import { getCaseRequest } from "../../shared/services/request-api-services";

import "./wedge-builder.css";

const WBHome = () => {
	const [openLoading, setOpenLoading] = useState(false);
	const [rowData, setRowData] = useState([]);
	const { selectedModule } = useContext(GlobalStateContext);

	async function fetchCaseRequest(selectedModule) {
		setOpenLoading(true);
		const jobType = selectedModule === "Wedge Builder" ? "WEDGE" : "GAP";
		const data = await getCaseRequest(jobType);

		if (data.data !== null) {
			setRowData(data.data);
			setOpenLoading(false);
		} else {
			setOpenLoading(false);
			toast.error(data.error.message);
		}
	}

	// biome-ignore lint/correctness/useExhaustiveDependencies: <function changes on every re-render>
	useEffect(() => {
		fetchCaseRequest(selectedModule);
	}, [selectedModule]);

	const colDefs = [
		{
			headerName: "Request ID",
			field: "wbID",
			filter: "agTextColumnFilter",
			minWidth: 150,
			flex: 1,
			cellRenderer: (params) => (
				<b>
					<Link
						rel="noopener noreferrer"
						to="/wb-output-review"
						state={{ caseID: params.data.caseID, requestID: params.data.wbID }}
					>
						{params.value}
					</Link>
				</b>
			),
		},
		{
			headerName: "Account Name",
			field: "accountName",
			filter: "agTextColumnFilter",
			minWidth: 150,
			flex: 1,
		},
		{
			headerName: "Assigned To",
			field: "assignedTo",
			filter: "agTextColumnFilter",
			minWidth: 150,
			flex: 1,
		},
		{
			headerName: "Case ID",
			field: "caseID",
			filter: "agTextColumnFilter",
			minWidth: 150,
			flex: 1,
		},
		{
			headerName: "Request Date",
			field: "requestDate",
			filter: CustomDateFilter,
			minWidth: 200,
			flex: 1,
			valueFormatter: (params) =>
				params.value ? moment(params.value).format("MM-DD-yyyy") : "",
			enableFloatingFilter: false,
		},
		{
			headerName: "Status",
			field: "status",
			filter: "agTextColumnFilter",
			minWidth: 150,
			flex: 1,
		},
	];

	return (
		<>
			<div
				id="content-wrapper"
				class="d-flex flex-column"
				style={{ flex: "1" }}
			>
				<div id="content">
					<Header />
					<div class="container-fluid">
						<hr class="sidebar-divider my-0" />
						<div class="row">
							<div class="col-xl-12 col-md-6 mb-4" />
						</div>
						<div class="row">
							<div class="col-xl-12 col-md-12 mb-4">
								<div class="card shadow mb-4">
									<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
										<h6 class="infoMessage">
											List of all Open and In-Progress Wedge and Gap requests,
											click on the Request ID to review
										</h6>
									</div>
									<div class="card-body">
										<div
											className={"ag-theme-quartz"}
											style={{ width: "100%", height: 655 }}
										>
											<FRPAgGrid columnDefs={colDefs} rowData={rowData} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Backdrop
					sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={openLoading}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			</div>
		</>
	);
};
export default WBHome;
