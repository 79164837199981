import { Suspense } from "react";
import { HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IdleLogout from "./idleLogout";

import "./App.css";

function App() {
	return (
		<Suspense fallback="...loading">
			<HashRouter>
				<IdleLogout />
			</HashRouter>
			<ToastContainer
				theme="colored"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</Suspense>
	);
}

export default App;
