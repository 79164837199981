import axios from "axios";

export const getAPI = async (_url) => {
	try {
		const config = {
			method: "get",
			maxBodyLength: Number.POSITIVE_INFINITY,
			url: _url,
			headers: {
				"Content-Type": "application/json",
			},
		};

		const response = await axios.request(config);
		return { data: response.data, loading: false, error: null };
	} catch (error) {
		return { data: null, loading: false, error: error };
	}
};

export const postAPI = async (_url, _body) => {
	try {
		const config = {
			method: "post",
			url: _url,
			headers: {
				"Content-Type": "application/json",
			},
			data: _body,
		};
		const response = await axios.request(config);
		return { data: response.data, loading: false, error: null };
	} catch (error) {
		return { data: null, loading: false, error: error };
	}
};

export const putAPI = async (_url, _body, _header) => {
	try {
		const config = {
			method: "put",
			url: _url,
			headers: _header,
			data: _body,
		};
		const response = await axios.request(config);
		return { data: response.data, loading: false, error: null };
	} catch (error) {
		return { data: null, loading: false, error: error };
	}
};

export const deleteAPI = async (url, id) => {
	try {
		const response = await axios.delete(url, id);
		return { data: response.data, loading: false, error: null };
	} catch (error) {
		return { data: null, loading: false, error: error };
	}
};
