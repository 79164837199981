import { Modal as BaseModal } from "@mui/base/Modal";
import { css, styled } from "@mui/system";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";

const KeywordPositionsCellRenderer = (props) => {
	const [open, setOpen] = React.useState(false);

	if (props.value === null) {
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					paddingTop: "5px",
				}}
			/>
		);
	}

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const { pageText, keywordPositions } = props.value;

	const keywords = Object.keys(keywordPositions);
	const keywordsString = keywords.join(", ");

	let keywordPositionDefinitions = [];

	for (const [keyword, positions] of Object.entries(keywordPositions)) {
		for (const [start, end] of positions) {
			keywordPositionDefinitions.push({
				text: keyword,
				start,
				end,
				color: "lightcoral",
			});
		}
	}

	keywordPositionDefinitions.sort((a, b) => a.start - b.start);

	// Remove cases where a keyword's location occurs within the location of a previous keyword
	let previousEnd = -1;
	keywordPositionDefinitions = keywordPositionDefinitions.filter(
		(keywordPositionDefinition) => {
			if (keywordPositionDefinition.start >= previousEnd) {
				previousEnd = keywordPositionDefinition.end;
				return true;
			}
			return false;
		},
	);

	const textDefinitions = [];

	let keyNumber = 1;

	for (const [
		index,
		keywordPositionDefinition,
	] of keywordPositionDefinitions.entries()) {
		// Add text between previous keyword (or page text start) and current keyword
		textDefinitions.push({
			text: pageText.slice(
				index > 0 ? keywordPositionDefinitions[index - 1].end : 0,
				keywordPositionDefinition.start,
			),
			color: null,
			key: `text-${keyNumber}`,
		});
		keyNumber += 1;

		// Add definition for keyword
		textDefinitions.push({
			text: pageText.slice(
				keywordPositionDefinition.start,
				keywordPositionDefinition.end,
			),
			color: keywordPositionDefinition.color,
			key: `text-${keyNumber}`,
		});
		keyNumber += 1;
	}

	if (textDefinitions.length > 0) {
		const lastPositionEnd =
			keywordPositionDefinitions[keywordPositionDefinitions.length - 1].end;
		textDefinitions.push({
			text: pageText.slice(lastPositionEnd, pageText.length),
			color: null,
			key: `text-${keyNumber}`,
		});
	}

	// If no keywords were found, use the whole page text
	if (textDefinitions.length === 0) {
		textDefinitions.push({
			text: pageText,
			color: null,
			key: `text-${keyNumber}`,
		});
	}

	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				paddingTop: "5px",
			}}
		>
			<TriggerButton type="button" onClick={handleOpen}>
				{keywordsString}
			</TriggerButton>
			<Modal
				aria-labelledby="unstyled-modal-title"
				aria-describedby="unstyled-modal-description"
				open={open}
				onClose={handleClose}
				slots={{ backdrop: StyledBackdrop }}
			>
				<ModalContent sc={{ width: 400 }}>
					<h2 id="unstyled-modal-title" className="modal-title">
						Keywords in page text: {keywordsString}
					</h2>
					<p id="unstyled-modal-description" className="modal-description">
						{textDefinitions.map((textDefinition) => (
							<span
								key={textDefinition.key}
								style={{
									backgroundColor: textDefinition.color
										? textDefinition.color
										: "transparent",
									whiteSpace: "pre-wrap",
								}}
							>
								{textDefinition.text}
							</span>
						))}
					</p>
				</ModalContent>
			</Modal>
		</div>
	);
};

const Backdrop = React.forwardRef((props, ref) => {
	const { open, className, ...other } = props;
	return (
		<div
			className={clsx({ "base-Backdrop-open": open }, className)}
			ref={ref}
			{...other}
		/>
	);
});

Backdrop.propTypes = {
	className: PropTypes.string.isRequired,
	open: PropTypes.bool,
};

const blue = {
	200: "#99CCFF",
	300: "#66B2FF",
	400: "#3399FF",
	500: "#007FFF",
	600: "#0072E5",
	700: "#0066CC",
};

const grey = {
	50: "#F3F6F9",
	100: "#E5EAF2",
	200: "#DAE2ED",
	300: "#C7D0DD",
	400: "#B0B8C4",
	500: "#9DA8B7",
	600: "#6B7A90",
	700: "#434D5B",
	800: "#303740",
	900: "#1C2025",
};

const Modal = styled(BaseModal)`
    position: fixed;
    z-index: 1300;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

const StyledBackdrop = styled(Backdrop)`
    z-index: -1;
    position: fixed;
    inset: 0;
    background-color: rgb(0 0 0 / 0.5);
    -webkit-tap-highlight-color: transparent;
  `;

const ModalContent = styled("div")(
	({ theme }) => css`
      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: 500;
      text-align: start;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow: hidden;
      background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border-radius: 8px;
      border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
      box-shadow: 0 4px 12px
        ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
      padding: 24px;
      color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};
      min-width: 400px;
      max-width: 800px;

      & .modal-title {
        margin: 0;
        line-height: 1.5rem;
        margin-bottom: 8px;
      }
  
      & .modal-description {
        margin: 0;
        line-height: 1.5rem;
        font-weight: 400;
        color: ${theme.palette.mode === "dark" ? grey[400] : grey[800]};
        margin-bottom: 4px;
        max-height: 800px;
        overflow-y: auto;
      }
    `,
);

const TriggerButton = styled("button")(
	({ theme }) => css`
      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.5;
      padding: 8px 16px;
      border-radius: 8px;
      transition: all 150ms ease;
      cursor: pointer;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
      color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
      box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  
      &:hover {
        background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
        border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
      }
  
      &:active {
        background: ${theme.palette.mode === "dark" ? grey[700] : grey[100]};
      }
  
      &:focus-visible {
        box-shadow: 0 0 0 4px ${theme.palette.mode === "dark" ? blue[300] : blue[200]};
        outline: none;
      }
    `,
);

export default KeywordPositionsCellRenderer;
