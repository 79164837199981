import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { GlobalStateContext } from "./GlobalStateContext";

const Protected = ({ children, component }) => {
	const { userRole, isAuthenticated, setCurrentRoute } =
		useContext(GlobalStateContext);

	if (!isAuthenticated) {
		return <Navigate to="/" replace />;
	}

	if (component === "permanent-issue-register" && userRole !== "ADMIN") {
		return <Navigate to="/" replace />;
	}

	setCurrentRoute(component);
	return children;
};
export default Protected;
